import React from "react";
// import Helmet from "react-helmet";
import { Link, graphql } from 'gatsby'

import '../components/App.css';

import Layout from '../components/layout'
import SEO from '../components/seo'
import tags from '../components/tags'
import Subscribe from '../components/subscribe'

export default function Index({ data }) {
  const { edges: posts } = data.allMarkdownRemark;
  return <Layout>
    <SEO title="Blog" keywords={tags} />

    <div className="page">
      <div className="page-content-container background-gradients">

        <div className="page-content top-padding">

          {posts
            .filter(post => post.node.frontmatter.title.length > 0)
            .map(({ node: post }) => {
              return (
                <div className="blog-post-preview" key={post.id}>
                  <h1 className="blog-listing-title">
                    <Link to={post.frontmatter.path}>{post.frontmatter.title}</Link>
                  </h1>
                  <h6>{`Posted on ${post.frontmatter.date}`}</h6>
                  <p>{post.frontmatter.description}</p>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  </Layout>;
}

export const pageQuery = graphql`
  query indexQueryAndIndexQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
            description
          }
        }
      }
    }
  }
`;
